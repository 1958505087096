import { SVGProps, Ref, forwardRef } from 'react'

const SvgShield = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinejoin="round"
      d="M4.5 2.5h19v7.266c0 6.601-3.657 12.66-9.5 15.734A17.778 17.778 0 0 1 4.5 9.766V2.5Z"
      clipRule="evenodd"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgShield)
export default ForwardRef
